import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  // eslint-disable-next-line max-len
  baseUrl = 'https://livelife-app-func.azurewebsites.net/api/send-register-otp-func?code=qEYU8Luguko66sdOilz4WH6/iJ7sfMejUcmzg8yE30nQH4MwvJNvug==';
  phone: string;
  otp: number;
  appName = 'Wozani Nizothenga';
  // referrer: string;

  constructor(
    private http: HttpClient
  ) { }

  // sets the user's phone number in variable for later retrieval
  setPhoneNumber(phone: string) {
    this.phone = phone;
  }

  // returns the phone number value stored in variable
  getGetPhoneNumber() {
    return this.phone;
  }

  // get the OTP
  getNewOTP(msisdn) {
    return this.http.get<number>(`${this.baseUrl}&appName=${this.appName}&msisdn=${msisdn}`);
  };

  setOTP(otp: number) {
    this.otp = otp;
  }

  // sets the referrer (agent code) in variable for later retrieval
  // setReferrer(referrer: string) {
  //   this.referrer = referrer;
  // }

  // returns the referrer value stored in variable
  // getReferrer() {
  //   console.log('refrerrer', this.referrer);
  //   // return default referrer if not provided (for this app only)
  //   if (!this.referrer)
  //   {
  //     return 'WN000000';
  //   }

  //   return this.referrer;
  // }

  getCurrentOTP() {
    return this.otp;
  }
}
