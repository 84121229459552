import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IDistributorLookup } from '../models/stokvels/IDistributorLookup';

@Injectable({
  providedIn: 'root'
})
export class StokvelService {
  // baseUrl = 'https://stokvel-user-api.azurewebsites.net/api/';
  // baseUrl = 'https://localhost:44378/api/app/';
  baseUrl = 'https://livelife-admin-httpapi-host.azurewebsites.net/api/app/';

  httpOptions: {
    observe: 'response';
    responseType: 'json';
  };

  constructor(private http: HttpClient) { }

  // logs the user
  create(createStokvel) {
    console.log('Register', createStokvel);
    return this.http.post(`${this.baseUrl}app-stokvel`, createStokvel);
  };

  getUserEntries(userId) {
    return this.http.get(`${this.baseUrl}stokvel-entry/${userId}/by-app-user-id/${environment.tenantId}`);
  }

  getStokvelTypeLookup(tenantId) {
    return this.http.get(`${this.baseUrl}app-stokvel/stokvel-type-lookup/${tenantId}`);
  }

  getStokvelLookup(userId, tenantId) {
    return this.http.get<any>(`${this.baseUrl}app-stokvel/stokvel-lookup-by-app-user-id?appUserId=${userId}&tenantId=${tenantId}`);
  }

  getDistributorsByProvince(provinceId, tenantId) {
    return this.http.get<any>(`${this.baseUrl}distributor/by-province?provinceId=${provinceId}&tenantId=${tenantId}`);
  }
}
